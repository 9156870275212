
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip, Cell } from 'recharts';
import { DailyPrice } from '@/types/price';
import { memo, useMemo } from 'react';
import { Skeleton } from '@/components/ui/skeleton';
import { DaySelector } from './price-chart/DaySelector';
import { TomorrowMessage } from './price-chart/TomorrowMessage';
import { ChartTooltip } from './price-chart/ChartTooltip';
import { CurrentTimeMarker } from './price-chart/CurrentTimeMarker';
import { formatAggregatedData, getChartMargins, roundToNext5 } from './price-chart/ChartConfig';
import { format } from 'date-fns';
import { fi } from 'date-fns/locale';

interface PriceChartProps {
  data: DailyPrice[];
  aggregationType: string;
  isLoading?: boolean;
  onDayChange?: (day: 'today' | 'tomorrow') => void;
  selectedDay?: 'today' | 'tomorrow';
  tomorrowPricesAvailable?: boolean;
  isHistoricalChart?: boolean;
}

const PriceChart = memo(({ 
  data = [], 
  aggregationType, 
  isLoading,
  onDayChange,
  selectedDay = 'today',
  isHistoricalChart = false,
}: PriceChartProps) => {
  console.log('PriceChart received data:', {
    dataLength: data?.length ?? 0,
    aggregationType,
    firstItem: data?.[0],
    lastItem: data?.[data?.length - 1],
    isLoading,
    selectedDay,
    hasData: data && data.length > 0
  });

  const aggregateData = useMemo(() => 
    formatAggregatedData(data, aggregationType),
    [data, aggregationType]
  );

  const labelInterval = useMemo(() => 
    Math.max(1, Math.floor(aggregateData.length / 10)),
    [aggregateData.length]
  );

  // Only show current hour highlight if it's today's chart and hourly view
  const shouldHighlightCurrentHour = useMemo(() => {
    return selectedDay === 'today' && 
           aggregationType === 'hourly' && 
           !isHistoricalChart;
  }, [selectedDay, aggregationType, isHistoricalChart]);

  const currentHourKey = useMemo(() => {
    if (shouldHighlightCurrentHour) {
      const now = new Date();
      return format(now, 'HH:00', { locale: fi });
    }
    return null;
  }, [shouldHighlightCurrentHour]);

  if (isLoading) {
    return (
      <div className="w-full h-[400px] mt-6">
        <Skeleton className="w-full h-full" />
      </div>
    );
  }

  // Show tomorrow message if it's tomorrow's data and we have 1 or fewer prices
  const shouldShowTomorrowMessage = selectedDay === 'tomorrow' && (!data?.length || data.length <= 1);
  
  // Determine if price table should be shown
  const shouldShowPriceTable = !shouldShowTomorrowMessage && data?.length > 1;

  return (
    <div className="space-y-4">
      {onDayChange && (
        <DaySelector 
          selectedDay={selectedDay} 
          onDayChange={onDayChange}
        />
      )}
      
      {shouldShowTomorrowMessage ? (
        <TomorrowMessage onRefresh={() => onDayChange?.('tomorrow')} />
      ) : (
        <div className="w-full h-[400px]">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart 
              data={aggregateData} 
              margin={getChartMargins()}
            >
              <XAxis 
                dataKey="time"
                interval={labelInterval}
                angle={-45}
                textAnchor="end"
                height={50}
                dy={10}
                dx={-8}
                tick={{ fontSize: 12 }}
              />
              <YAxis 
                tickFormatter={(value: number) => `${Math.floor(value)}`}
                domain={[0, (dataMax: number) => roundToNext5(Math.max(10, dataMax))]}
                tickMargin={8}
                width={35}
                tick={{ fontSize: 12 }}
              />
              <Tooltip content={<ChartTooltip aggregationType={aggregationType} isHistoricalChart={isHistoricalChart} />} />
              <Bar 
                dataKey="price" 
                fill="hsl(var(--primary))"
                radius={[4, 4, 0, 0]}
              >
                {shouldHighlightCurrentHour && aggregateData.map((entry, index) => (
                  <Cell 
                    key={`cell-${index}`}
                    fill={entry.time === currentHourKey ? '#1EAEDB' : 'hsl(var(--primary))'}
                  />
                ))}
              </Bar>
              <CurrentTimeMarker show={selectedDay === 'today' && !isHistoricalChart} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
});

PriceChart.displayName = "PriceChart";

export default PriceChart;
