
import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { RefreshCw } from 'lucide-react';

interface TomorrowMessageProps {
  onRefresh: () => void;
}

export const TomorrowMessage = ({ onRefresh }: TomorrowMessageProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleRefresh = () => {
    setIsLoading(true);
    onRefresh();
    
    // Show loading state for 500ms
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  return (
    <Card>
      <CardContent className="pt-6">
        <p className="text-center text-muted-foreground">
          Seuraavan päivän hinnat päivittyvät sivustolle n. klo 14.15.
        </p>
        <div className="flex justify-center mt-4">
          <Button onClick={handleRefresh} disabled={isLoading}>
            <RefreshCw className={`mr-2 h-4 w-4 ${isLoading ? 'animate-spin' : ''}`} />
            Päivitä
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};
